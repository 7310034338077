$body-bg: rgb(47, 49, 54);
$body-color: #FFFFFF;

$main-1: #e26f11;
$main-2: #e9f811; 
$nav-tabs-link-active-color: white;

$colors: (
  "main-1": $main-1,
  "main-2": $main-2
);

$theme-colors: (
  "primary": $main-1,
  "secondary": $main-2,
  "darker": $body-bg
);

$spacers: (
  0_5: 0.125rem,
);

@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap");

.clear:before,
.clear:after {
  content: " ";
  display: table;
}

.clear:after {
  clear: both;
}

html,
body {
  height: 100%;
}

:root {
  --main-color-1: #e26f11;

  --main-color-1-tonedown: #a55f26;
  --main-color-1-tonedown-alpha: #a55f2688;
  --main-color-1-highlight: #ffa357;

  --main-color-2: #e9f811;

  --main-bg-light: rgb(54, 57, 63);
  --main-bg-dark: rgb(47, 49, 54);

  --admin-visible: none;
}

.font-title {
  font-family: "Luckiest Guy", cursive;
  padding-top: 0.3rem;
}

.p-0_5 {
  padding: 0.125rem !important;
}

.main-color-1 {
  color: var(--main-color-2);
}

.main-color-2 {
  color: var(--main-color-1);
}

.admin-tabs > .tab-content {
  min-height: 0;
  overflow-y: auto;
}  

.admin-tabs > .tab-content, .admin-tabs > .tab-content > .tab-pane {
  height: 100%;
}

.input-dark {
  background: var(--dark);
}

@mixin input-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%)) {
  color: color-yiq($background);
  @include gradient-bg($background);
  border-color: $border;
  @include box-shadow($btn-box-shadow);

  @include hover() {
    color: color-yiq($hover-background);
    @include gradient-bg($hover-background);
    border-color: $hover-border;
  }

  &:focus,
  &.focus {
    color: color-yiq($hover-background);
    @include gradient-bg($hover-background);
    border-color: $hover-border;

    // @if $enable-shadows {
    //   @include box-shadow($btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), .5));
    // }

    // @else {
    //   // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: unset;//s$btn-focus-width rgba(mix(color-yiq($background), $border, 15%), .5);
    // }
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: color-yiq($background);
    background-color: $background;
    border-color: $border;

    // Remove CSS gradients if they're enabled
    @if $enable-gradients {
      background-image: none;
    }
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show>&.dropdown-toggle {
    color: color-yiq($active-background);
    background-color: $active-background;

    @if $enable-gradients {
      background-image: none; // Remove the gradient for the pressed/active state
    }

    border-color: $active-border;

    &:focus {
      @if $enable-shadows and $btn-active-box-shadow !=none {
        @include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), .5));
      }

      @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), .5);
      }
    }
  }
}

@each $color,
$value in $theme-colors {
  .input-#{$color} {
    @include input-variant($value, $white);
  }
}


// .admin-tabs .active {
//   background-color: red;
// }

@import "navigation";
@import "chat-overlay";
@import "video-player";