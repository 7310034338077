.video-js:focus {
    outline: none;
}

.full-page {
  position: absolute;
  width: 100%;
  top: 0px;
  height: 100%;
}
