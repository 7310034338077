.navMenu {
  display: block;
  position: absolute;
  top: 10px;
  left: 10px;

  z-index: 1;

  -webkit-user-select: none;
  user-select: none;
}

#navMenu .inputHitTest {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;

  cursor: pointer;

  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#navMenu .burgerSlice {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;

  background: var(--main-color-1);
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#navMenu .burgerSlice:nth-last-child(2) {
  transform-origin: 0% 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#navMenu.checked .burgerSlice {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: var(--main-color-2);
}

/*
 * But let's hide the middle one.
 */
#navMenu.checked .burgerSlice:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#navMenu.checked .burgerSlice:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#navMenu .menuContent {
  position: fixed;
  display: block;
  left: 0px;
  top: 0px;
  width: 300px;
  height: 100vh;
  margin: 0;
  padding: 30px;
  padding-top: 50px;

  background: rgb(47, 49, 54);
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */

  transform-origin: 0% 0%;
  transform: translate(-100%, 0);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

/*
 * And let's slide it in from the left
 */
#navMenu.checked .menuContent {
  transform: none;
}


#navMenu .menuContent li {
  padding: 2px 3px;
  font-size: 22px;
}

#navMenu .noPad {
  padding: 0px;
}

/* #navMenu .menuContent li * {
  padding: 2px;
  font-size: 22px;
} */

#navMenu .menuContent button {
  background: transparent;
  border: none;
  text-decoration: none;
  color: #ffffff;
  transition: color 0.3s ease;
}

#navMenu .menuContent button:hover {
  color: var(--main-color-1);
}



#navMenu .menuContent a {
  background: transparent;
  border: none;
  text-decoration: none;
  color: #ffffff;

  transition: color 0.3s ease;
}

#navMenu .menuContent a:hover {
  color: var(--main-color-1);
}

#navMenu .backgroundBlocker {
  display: block;
  position: absolute;
  left: -10px;
  top: -10px;
  z-index: -1;
  background: black;
  opacity: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
}

#navMenu.checked .backgroundBlocker {
  opacity: 0.4;
  width: 100vw;
  pointer-events: auto;
}
