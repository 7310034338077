:root {
  --chat-overlay-height: 25vh;
}

.chatOverlay {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 25vh; /* Full height (cover the whole page) */
  height: var(--chat-overlay-height);
  left: 0;
  bottom: 0;
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  margin: 20px;
  overflow: hidden
}

.chatOverlay .chat-message {
  max-height: 4em;
}

.chatOverlay .fade-in-out {
  animation: fadeInOut 10s forwards;
}

.chatOverlay .message-header {
  // This is just a default, 
  background: var(--main-color-1-tonedown-alpha);
}

.chatOverlay .message-content {
  word-wrap: break-word;
  background: #44444488;
  overflow: hide;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
